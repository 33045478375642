import React from 'react';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import profilPic from '../assets/profil_pic.webp';
import '../styles/about.scss';
import Layout from '../components/layout';

export default function About(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="about">
        <div className="message">
          <div className="imgContainer">
            <img src={profilPic} className="profile" alt={t('ABOUT.ALT_IMG')} />
          </div>
          <div className="content">
            <div className="text">{t('ABOUT.TEXT_1')}</div>
            <div className="text">{t('ABOUT.TEXT_2')}</div>
            <div className="text">{t('ABOUT.TEXT_3')}</div>
            <div className="text">{t('ABOUT.TEXT_4')}</div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
